import 'bootstrap';
import '@splidejs/splide';
import Splide from '@splidejs/splide';


Splide.defaults = {
    autoWidth: true,
    speed: 1000,
    drag: true,
    snap: true,
    type   : 'loop',
    perPage: 5,
    pagination: false,
    focus: 'center',
    pauseOnHover: true,
    pauseOnFocus: true,
  }
var splideCheck = document.getElementsByClassName("splide").length;
if (splideCheck) {
    var main = new Splide(".splide");
    main.mount();
}